import * as React from 'react';
import PapFooter from 'components/pap-footer.js';
import PapDonorsDesktop from 'components/pap-donors-desktop.js';
import PapDonorsMobile from 'components/pap-donors-mobile.js';
import MediaQuery from 'react-responsive';

// markup
const DonorPage = () => {	
	const handleMediaQueryChange = (matches) => {
		return matches;
	}

	return (
		<main style={{backgroundColor: '#000', height: '100%'}}>
			<MediaQuery maxWidth={1224} onChange={handleMediaQueryChange}>
				<PapDonorsMobile/>
				<PapFooter
					isMobile={true}
				/>
			</MediaQuery>
			<MediaQuery minWidth={1224} onChange={handleMediaQueryChange}>
				<PapDonorsDesktop/>
				<PapFooter
					isMobile={false}
				/>
			</MediaQuery>
		</main>
	);
};

export default DonorPage;
