import * as React from 'react';
import { Link } from "gatsby"
import { 
	Box,
	Grid,
	Typography,
} from '@mui/material';
import LogoDowin from "images/logodowin.png";
import QrApp from "images/qrapp.png";
import LogoDowinWhite from "images/logodowinWhite.png";

// styles
const pageStyles = {
	fontFamily: 'Quicksand',
};
const topBar = {
	height: '56px',
	marginTop: '0px',
    paddingLeft: '16px',
    marginBottom: '16px',
}
const imageContainer = {
	height: '36.7px',
}
const qrContainer = {
	padding: '32px',
    textAlign: 'center',
}
const qrResize= {
	width: '180px',
    alignSelf: 'center',
}
const logoContainer = {
    height: '60px',
}
const informationText = {
    fontFamily: 'Quicksand',
    color: '#767676',
	fontSize: '20px',
	lineHeight: '25px',
	fontWeight: '700',
}
const informationLink = {
    fontFamily: 'Quicksand',
    color: '#3239E5',
	fontSize: '20px',
	lineHeight: '25px',
	fontWeight: '700',
}
const sloganWhite = {
	fontFamily: 'Quicksand',
    color: '#FFFFFF',
	fontSize: '36px',
	lineHeight: '45px',
	alignItems: 'center'
}
const sloganWhiteSmall = {
	fontFamily: 'Quicksand',
    color: '#FFFFFF',
	fontSize: '24px',
	lineHeight: '32px',
	alignItems: 'center'
}
const subtitle = {
    fontFamily: 'Quicksand',
	fontSize: '36px',
	lineHeight: '45px',
}
const stepsTitle = {
	fontFamily: 'Quicksand',
	fontSize: '48px',
	lineHeight: '60px',
	fontWeight: '700',
}
const stepsTextBold = {
	fontFamily: 'Quicksand',
	fontSize: '20px',
	lineHeight: '25px',
	fontWeight: '700',
}
const stepsTextIcon = {
	fontFamily: 'Quicksand',
	fontSize: '56px',
	lineHeight: '70px',
	fontWeight: '700',
}
const verticalPadding40 = {
	paddingTop: '40px',
	paddingBottom: '40px',
}
const paddingLeft110 = {
	paddingLeft: '110px',
}
const containerPadding = {
	paddingLeft: '110px',
    paddingBottom: '40px',
}
const darkBlue = {
    backgroundColor: '#3239E5',
}
const lightBlue = {
    backgroundColor: '#C6DCE8',
}
const white = {
    backgroundColor: '#FFFFFF',
}

export default function PapDonorsDesktop(props) {
    return (
        <Box style={pageStyles}>
            <Box style={darkBlue}>
                <Grid container spacing={2} style={topBar}>
                    <Grid item xs={12}>
                        <Link to="/">
                            <img
                                alt="dowin Logo"
                                style={imageContainer}
                                src={LogoDowinWhite}
                            />
                        </Link>
                    </Grid>
                </Grid>
                <Grid justifyContent={'center'} style={verticalPadding40} container spacing={2}>
                    <Grid item xs={0}>
                        {/*Aquí irán los proyectos exitosos*/}
                    </Grid>
                    <Grid item xs={10}>
                        <Typography style={sloganWhite} display={"inline"} fontWeight="700">Dona a proyectos solidarios </Typography>
                        <Typography style={sloganWhite} display={"inline"}>🤝 de ONGs, entidades sin ánimos de lucro, atletas, entidades deportivas, educativas de manera </Typography>
                        <Typography style={sloganWhite} display={"inline"} fontWeight="700">fácil, segura y transparente, </Typography>
                        <Typography style={sloganWhite} display={"inline"}>y además participas para </Typography>
                        <Typography style={sloganWhite} display={"inline"} fontWeight="700">ganar regalos solidarios 🎉</Typography>
                        <Grid container direction={"column"} justifyContent={'center'} sx={qrContainer}>
                            <img
                                alt="app qr code"
                                style={qrResize}
                                src={QrApp}
                            />
                            <Typography style={sloganWhiteSmall}>¡Escanea para descargar la aplicación! </Typography>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Box>
            <Box style={white}>
                <Grid style={verticalPadding40} container spacing={2}>
                    <Grid style={paddingLeft110} item xs={12}>
                        <Typography variant="h1" style={stepsTitle}>¿Cómo donar?</Typography>
                    </Grid>
                </Grid>
                <Grid style={containerPadding} container spacing={2}>
                    <Grid item xs={5}>
                        <Box style={{paddingTop: '16px'}}>
                            <Typography style={stepsTextIcon}>📲</Typography>
                            <Typography style={stepsTextBold}>1 Descárgate la app dowin y crea tu cuenta</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box style={{paddingTop: '16px'}}>
                            <Typography style={stepsTextIcon}>💶</Typography>
                            <Typography style={stepsTextBold}>2 Carga tu cartera con al menos 5 €.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box style={{paddingTop: '16px'}}>
                            <Typography style={stepsTextIcon}>🔍</Typography>
                            <Typography style={stepsTextBold}>3 Consulta los proyectos solidarios disponibles.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box style={{paddingTop: '16px'}}>
                            <Typography style={stepsTextIcon}>🎁</Typography>
                            <Typography style={stepsTextBold}>4 Dona a tus proyectos preferidos y espera posibles regalos.</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}