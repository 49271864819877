import * as React from 'react';
import { Link } from "gatsby"
import { 
	Box,
	Button,
	Grid,
	Typography,
} from '@mui/material';
import LogoDowinWhite from "images/logodowinWhite.png";

// styles
const pageStyles = {
	fontFamily: 'Quicksand',
};
const topBar = {
	height: '36px',
	marginTop: '0px',
    paddingLeft: '16px',
}
const imageContainer = {
	height: '36.7px',
}
const logoContainer = {
    height: '60px',
}
const informationText = {
    fontFamily: 'Quicksand',
    color: '#767676',
	fontSize: '12px',
	lineHeight: '14px',
	fontWeight: '700',
}
const informationLink = {
    fontFamily: 'Quicksand',
    color: '#3239E5',
	fontSize: '12px',
	lineHeight: '14px',
	fontWeight: '700',
}
const sloganWhite = {
	fontFamily: 'Quicksand',
    color: '#FFFFFF',
	fontSize: '12px',
	lineHeight: '16px',
	alignItems: 'center'
}
const subtitle = {
    fontFamily: 'Quicksand',
	fontSize: '12px',
	lineHeight: '14px',
}
const stepsTitle = {
	fontFamily: 'Quicksand',
	fontSize: '18px',
	lineHeight: '20px',
	fontWeight: '700',
}
const stepsTextBold = {
	fontFamily: 'Quicksand',
	fontSize: '12px',
	lineHeight: '14px',
	fontWeight: '700',
}
const stepsTextIcon = {
	fontFamily: 'Quicksand',
	fontSize: '30px',
	lineHeight: '24px',
	fontWeight: '700',
}
const horizontalPadding35 = {
	paddingLeft: '35px',
	paddingRight: '35px',
}
const horizontalPadding25 = {
	paddingLeft: '25px',
	paddingRight: '25px',
}
const verticalPadding20 = {
	paddingTop: '20px',
	paddingBottom: '20px',
}
const verticalPadding40 = {
	paddingTop: '40px',
	paddingBottom: '40px',
}
const paddingLeft110 = {
	paddingLeft: '110px',
}
const containerPadding = {
	paddingLeft: '110px',
    paddingBottom: '40px',
}
const blackButton = {
	fontFamily: 'Quicksand',
	fontSize: '10px',
	lineHeight: '12px',
	fontWeight: "700",
	backgroundColor: '#000000',
	width: '100%',
	borderRadius: '50px',
	padding: '8px',
    
}
const darkBlue = {
    backgroundColor: '#3239E5',
}
const lightBlue = {
    backgroundColor: '#C6DCE8',
}
const white = {
    backgroundColor: '#FFFFFF',
}
const loginBox = {
    backgroundColor: '#C6DCE8',
    width: '60%',
    borderRadius: '16px',
}
const imgContainer = {
	width: '244px',
	margin: '0 auto',
}

export default function PapDonorsMobile(props) {
    return (
        <Box style={pageStyles}>
            <Box style={darkBlue}>
                <Grid container spacing={2} style={topBar}>
                    <Grid item xs={12}>
                        <Link to="/">
                            <img
                                alt="dowin Logo"
                                style={imageContainer}
                                src={LogoDowinWhite}
                            />
                        </Link>
                    </Grid>
                </Grid>
                <Grid justifyContent={'center'} sx={verticalPadding20} container spacing={2}>
                    <Grid item xs={0}>
                        {/* Cambiar carrusel de proyectos exitosos */}
                    </Grid>
                    <Grid item xs={10}>
                        <Typography style={sloganWhite} display={"inline"} fontWeight="700">Dona a proyectos solidarios </Typography>
                        <Typography style={sloganWhite} display={"inline"}>🤝 de ONGs, entidades sin ánimos de lucro, atletas, entidades deportivas, educativas de manera </Typography>
                        <Typography style={sloganWhite} display={"inline"} fontWeight="700">fácil, segura y transparente, </Typography>
                        <Typography style={sloganWhite} display={"inline"}>y además participas para </Typography>
                        <Typography style={sloganWhite} display={"inline"} fontWeight="700">ganar regalos solidarios 🎉</Typography>
                        <Box mb={"32px"}></Box>
                            <a href='https://qrco.de/bcf2jF'>
                                <Button style={blackButton} variant="contained">Descarga ya la app</Button>
                            </a>
                        <Box mb={"32px"}></Box>
                    </Grid>
                </Grid>
            </Box>
            <Box style={white}>
                <Grid sx={[verticalPadding20, horizontalPadding35]} container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h1" style={stepsTitle}>¿Cómo donar?</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Box style={{paddingTop: '8px'}}>
                            <Typography pb={"10px"} style={stepsTextIcon}>📲</Typography>
                            <Typography style={stepsTextBold}>1 Descárgate la app dowin y crea tu cuenta</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box style={{paddingTop: '8px'}}>
                            <Typography pb={"10px"} style={stepsTextIcon}>💶</Typography>
                            <Typography style={stepsTextBold}>2 Carga tu cartera con al menos 5 €.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box style={{paddingTop: '8px'}}>
                            <Typography pb={"10px"} style={stepsTextIcon}>🔍</Typography>
                            <Typography style={stepsTextBold}>3 Consulta los proyectos solidarios disponibles.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box style={{paddingTop: '8px'}}>
                            <Typography pb={"10px"} style={stepsTextIcon}>🎁</Typography>
                            <Typography style={stepsTextBold}>4 Dona a tus proyectos preferidos y espera posibles regalos.</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box pb={"32px"}></Box>
            </Box>
        </Box>
    )
}